<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <header class="contributor-header">
      <div class="container">
        <div class="flex justify-content-between align-items-center">
          <h1><a href="javascript: void(0)" class="logo-img-wrap" v-if="learningCenterData"><img :src="learningCenterData.logo" /></a></h1>
          <div class="ml-auto">
            <a href="javascript: void(0)" class="logo-img-wrap"><img :src="$defaultProfileImage" /></a>
          </div>
        </div>
      </div>
    </header>
</template>

<script>

    export default {
        name: "contributor-header",
        props: ["learningCenter"],
        data(){
          return{
            learningCenterData:{},
            image:require('../../assets/images/logo/logo.svg')
          }
        },
      watch: {
        learningCenter(learningCenter){
          this.learningCenterData = learningCenter;
          console.log(learningCenter);
        }
      },
      created() {
          //console.log(this.contributor,"contributor");
          //console.log(this.contributorData,"contributorData");
      },

    }
</script>

<style lang="scss" scoped>
  header {
    background: #ffffff;
    padding: 5px 0 15px;
    border-bottom: 1px solid #CDD7D6;
    @media (min-width: 1200px){
      padding: 5px 0 12px;
    }
    @media (min-width: 1640px){
      padding: 5px 0 18px;
    }
    .logo-img-wrap {
      width: 130px;
      height: 60px;
      display: block;
      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
      @media (min-width: 1200px){
        width: 150px;
        height: 80px;
      }
      @media (min-width: 1640px){
        width: 165px;
        height: 95px;
      }
    }
    .btn-primary {
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      color: #ffffff;
      background: #2d9ad5;
      border: 1px solid #2d9ad5;
      padding: 10px 50px;
      box-shadow: none;
      border-radius: 8px;
      display: block;
      transition: 0.4s all ease-in-out;
      &:hover {
        background: #ffffff;
        color: #2d9ad5;
      }
    }
  }
</style>
