
<template>
    <div class="layout--full-page">
        <contributor-header :learningCenter="learningCenter"/>
        <router-view @setContributorLearningCenter="setContributorLearningCenter"></router-view>
        <front-footer />
    </div>
</template>

<script>

    import FrontFooter from '@/layouts/components/FrontFooter.vue'
    import ContributorHeader from '@/layouts/components/ContributorHeader.vue'

    export default {
        components: {
            FrontFooter,
          ContributorHeader,
        },
        data() {
            return {
              learningCenter:{},

            }
        },
        watch: {

        },
        computed: {

        },
        methods: {
          setContributorLearningCenter(learningCenter){
            this.learningCenter = learningCenter;
          }
        },
        created() {

        },
        beforeDestroy() {

        },
        mounted() {

        }
    }

</script>
